import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import Fuse from "fuse.js"

import { navigate, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActionArea from "@material-ui/core/CardActionArea"
import Hidden from "@material-ui/core/Hidden"
import Typography from "@material-ui/core/Typography"

import { localizedSlugPrefix } from "../gatsby/nodeHelpers"
import SEO from "../components/SEO"
import Breadcrumbs from "../components/Breadcrumbs"
import SectionTitle from "../components/SectionTitle"
import Filters from "../components/filters/Filters"
import SingleSelectFilter from "../components/filters/SingleSelectFilter"

import getSimilarTeamMembers from "../services/getSimilarTeamMembers"

import TextFilter from "../components/filters/TextFilter"
import FilterBy from "../components/filters/FilterBy"

import "../styles/teamPage.scss"

import SpecialisationGroup from "../components/team/SpecialisationGroup"

const useStyles = makeStyles(theme => ({
  pageTitle: {
    zIndex: 9,
  },
  card: {
    textAlign: "center",
    padding: 0,
  },
  cardAction: {
    display: "block",
    textAlign: "left",
  },
  cardContent: {
    minHeight: 50,
    padding: 0,
    textAlign: "left",
  },
  media: {
    height: 220,
  },
  name: {
    paddingTop: theme.spacing(2),
  },

  filters: {
    zIndex: 9,
  },
}))

const getMemberLocationMap = contactLocations => {
  const contactLocationsList = contactLocations.edges.map(r => r.node)

  const teamMemberContactLocationMap = {}

  contactLocationsList.forEach(cl => {
    cl.data.team_members
      .filter(tm => tm.team_member.document)
      .forEach(tm => {
        if (!teamMemberContactLocationMap[tm.team_member.document.uid]) {
          teamMemberContactLocationMap[tm.team_member.document.uid] = []
        }

        teamMemberContactLocationMap[tm.team_member.document.uid].push(cl.uid)
      })
  })

  return teamMemberContactLocationMap
}

const TeamPage = ({
  data: { teamPage, contactLocations, specialisations: specs },
  pageContext: { locale, slug, breadcrumbs },
  location,
}) => {
  const classes = useStyles()

  const memberLocationMap = getMemberLocationMap(contactLocations)

  const members = getSimilarTeamMembers()
    .map(member => {
      member.locations = memberLocationMap[member.uid]
      member.name = member.data.name.text
      member.searchTerms = [
        ...member.uid.split("-"),
        member.uid.split("-").join(" "),
        member.uid.split("-").reverse().join(" "),
        member.data.name.text,
        member.uid,
      ]
      member.isVisible = true
      return member
    })

  const fuse = new Fuse(members, {
    includeScore: true,
    threshold: 0.1,
    keys: ["searchTerms"],
    shouldSort: false,
  })

  const [isFilterActive, setIsFilterActive] = useState(false)
  const [filteredMembers, setFilteredMembers] = useState(members)
  const [locationFilters, setLocationFilters] = useState([])
  const [specialisationFilters, setSpecialisationFilters] = useState([])
  const [nameFilter, setNameFilter] = useState("")

  const locations = contactLocations.edges
    .map(r => r.node)
    .sort((a, b) => a.data.position > b.data.position ? 1 : (a.data.position < b.data.position ? -1 : 0))

  const specialisations = specs.edges
    .map(r => r.node)

  const schema = teamPage.data.schema_json.text || ""
  const ogp = teamPage.data.ogp_tags || ""
  const meta = {
    title: teamPage.data.meta_title,
    description: teamPage.data.meta_description,
    keywords: teamPage.data.meta_keywords,
    author: "Medicina Interventionala",
  }

  const [locationsOptions, setLocationsOptions] = useState(
    locations.map(location => ({
      data: location,
      id: location.id,
      uid: location.uid,
      name: location.data.long_title.text,
      selected: false,
    }))
  )

  const [specialisationsOptions, setSpecialisationsOptions] = useState(
    specialisations.map(specialisation => ({
      data: specialisation,
      id: specialisation.id,
      uid: specialisation.uid,
      name: specialisation.data.title.text,
      selected: false,
    }))
  )

  useEffect(() => {
    setIsFilterActive(false)
    let filtered = members

    if (nameFilter.length) {
      setIsFilterActive(true)
      const result = fuse.search(nameFilter)

      filtered = result.map(r => r.item)
    }

    const selectedLocations = locationFilters
      .filter(option => option.selected)
      .map(option => option.uid)

    if (selectedLocations.length) {
      setIsFilterActive(true)

      filtered = filtered.filter(member => {
        const found = member.locations.some(location =>
          selectedLocations.includes(location)
        )
        return found
      })
    }

    const selectedSpecialisations = specialisationFilters
      .filter(option => option.selected)
      .map(option => option.uid)

    if (selectedSpecialisations.length) {
      setIsFilterActive(true)

      filtered = filtered.filter(member => {
        const found = member.specialisations.map(s => s.uid).some(specialisation =>
          selectedSpecialisations.includes(specialisation)
        )
        return found
      })
    }

    setFilteredMembers([...filtered])
  }, [locationFilters, specialisationFilters, nameFilter])


  const onFilterByLocation = options => {
    setLocationFilters([...options])
  }

  const onFilterBySpecialisation = options => {
    setSpecialisationFilters([...options])
  }

  const onFilterByName = name => {
    setNameFilter(name)
  }

  const onRemoveLocationOption = optionToRemove => {
    const options = locationsOptions.map(option => {
      if (option.uid === optionToRemove.uid) {
        option.selected = false
      }

      return option
    })

    setLocationsOptions([...options])
    setLocationFilters([...options])
  }

  const onRemoveSpecialisationOption = optionToRemove => {
    const options = specialisationsOptions.map(option => {
      if (option.uid === optionToRemove.uid) {
        option.selected = false
      }

      return option
    })

    setSpecialisationsOptions([...options])
    setSpecialisationFilters([...options])
  }

  return (
    <>
      <SEO
        schema={schema}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
      />

      <main>
        <Container maxWidth="lg">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.pageTitle}>
              <SectionTitle component="h1">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {teamPage.data.title}{" "}
                    <span style={{ fontWeight: 700 }}>MONZA ARES</span>
                  </div>
                  <Hidden mdUp>
                    <div
                      style={{
                        width: 200,
                        height: 35,
                        backgroundColor: "#fff",
                      }}
                    >
                      <TextFilter
                        placeholder="Caută nume medic..."
                        onFilter={onFilterByName}
                      />
                    </div>
                  </Hidden>
                </div>
              </SectionTitle>
              <Hidden smDown implementation="css">
                <br />
              </Hidden>
              <Filters>
                <SingleSelectFilter
                  title="Filtrează după locație"
                  options={locationsOptions}
                  onFilter={options => onFilterByLocation(options)}
                  isFirst
                />
                <SingleSelectFilter
                  title="Filtrează după specializare"
                  options={specialisationsOptions}
                  onFilter={options => onFilterBySpecialisation(options)}
                />
                <div
                  itemprops={{
                    style: { marginLeft: "auto", marginRight: 8, width: 300 },
                  }}
                >
                  <Hidden smDown implementation="css">
                    <TextFilter
                      placeholder="Caută nume medic..."
                      onFilter={onFilterByName}
                    />
                  </Hidden>
                </div>
              </Filters>
            </Grid>
            <Grid item xs={12}>
              <FilterBy
                filters={[
                  {
                    onRemove: onRemoveLocationOption,
                    filter: locationFilters,
                  },
                  {
                    onRemove: onRemoveSpecialisationOption,
                    filter: specialisationFilters,
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              {isFilterActive && (
                <Grid container spacing={2}>
                  {filteredMembers.map(
                    member =>
                      member.isVisible && (
                        <Grid item xs={6} sm={4} md={2} key={member.uid}>
                          <Card className={classes.card} elevation={0} square>
                            <CardActionArea
                              className={classes.cardAction}
                              onClick={() => {
                                navigate(
                                  `${localizedSlugPrefix(member)}/${slug}/${member.uid
                                  }/`
                                )
                              }}
                            >
                              <GatsbyImage
                                className={classes.media}
                                alt={member.data.name.text}
                                image={member.data.profile_image.gatsbyImageData}
                              />
                            </CardActionArea>
                            <CardContent className={classes.cardContent}>
                              <Link
                                to={`${localizedSlugPrefix(member)}/${slug}/${member.uid
                                  }/`}
                              >
                                <Typography
                                  className="doctorName"
                                  component="h3"
                                  variant="body1"
                                >
                                  {member.data.name.text}
                                </Typography>
                                <Typography
                                  component="h3"
                                  variant="body2"
                                  className="doctorSpecialisation"
                                  color="primary"
                                >
                                  {member.specialisations.length > 0 &&
                                    member.specialisations[0].data.title.text}
                                </Typography>
                              </Link>
                            </CardContent>
                          </Card>
                        </Grid>
                      )
                  )}
                </Grid>
              )}

              {!isFilterActive && (
                <Grid container spacing={2}>
                  {specialisations.filter(specialisation => specialisation.data.team_members.length > 0).map((specialisation, index) => (
                    <SpecialisationGroup slug={slug} specialisation={specialisation.data} key={`member-group-${index}`} />
                  ))}

                </Grid>
              )}
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  )
}

export default TeamPage

export const query = graphql`
  query TeamPageQuery($slug: String!, $locale: String!) {
    settings: prismicSettings(lang: { eq: $locale }) {
      data {
        site_title
      }
    }

    teamPage: prismicTeam(uid: { eq: $slug }, lang: { eq: $locale }) {
      first_publication_date
      last_publication_date
      data {
        title
        description {
          text
        }
        schema_json {
          text
        }
        ogp_tags {
          content
          property
        }
        meta_title
        meta_description
        meta_keywords
      }
    }

    contactLocations: allPrismicContactLocation(
      filter: { lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          uid
          lang
          data {
            city {
              text
            }
            title {
              text
            }
            long_title {
              text
            }
            position
            team_members {
              team_member {
                document {
                  ... on PrismicTeamMember {
                    id
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }

    specialisations: allPrismicSpecialisation(
      filter: { lang: { eq: $locale } }
      sort: {fields: data___position, order: ASC}
    ) {
      edges {
        node {
          id
          uid
          lang
          data {
            title {
              text
            }
            position
            number_displayed_rows
            team_members {
              team_member {
                document {
                  ... on PrismicTeamMember {
                    id
                    uid
                    lang
                    data {
                      name {
                        text
                      }
                      profile_image {
                        alt
                        gatsbyImageData(layout: CONSTRAINED, width: 500)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

TeamPage.propTypes = {
  data: PropTypes.shape({
    settings: PropTypes.object.isRequired,
    teamPage: PropTypes.object.isRequired,
    contactLocations: PropTypes.object.isRequired,
    specialisations: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

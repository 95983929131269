import React from "react"

import SvgIcon from "@material-ui/core/SvgIcon"

const ArrowDownIcon = props => (
  <SvgIcon {...props} viewBox="0 0 40 40">
    <path
      d="M21.2,20L39.5,1.7c0.3-0.3,0.3-0.9,0-1.2c-0.3-0.3-0.9-0.3-1.2,0L20,18.8L1.7,0.5c-0.3-0.3-0.9-0.3-1.2,0
	c-0.3,0.3-0.3,0.9,0,1.2L18.8,20L0.5,38.3c-0.3,0.3-0.3,0.9,0,1.2c0.2,0.2,0.4,0.3,0.6,0.3s0.4-0.1,0.6-0.3L20,21.2l18.3,18.3
	c0.2,0.2,0.4,0.3,0.6,0.3s0.4-0.1,0.6-0.3c0.3-0.3,0.3-0.9,0-1.2L21.2,20z"
    />
  </SvgIcon>
)

export default ArrowDownIcon

import React from "react"

import SvgIcon from "@material-ui/core/SvgIcon"

const ArrowDownIcon = props => (
  <SvgIcon {...props} viewBox="0 0 40 21.1">
    <g transform="rotate(-180 20 10.5875)" id="svg_1">
      <path
        d="M20,20.9c-0.2,0-0.4-0.1-0.6-0.3L0.5,1.7c-0.3-0.3-0.3-0.9,0-1.2c0.3-0.3,0.9-0.3,1.2,0L20,18.8L38.3,0.5
		c0.3-0.3,0.9-0.3,1.2,0c0.3,0.3,0.3,0.9,0,1.2L20.6,20.6C20.4,20.8,20.2,20.9,20,20.9z"
      />
    </g>
  </SvgIcon>
)

export default ArrowDownIcon

import React, { Children } from "react"
import PropTypes from "prop-types"

import makeStyles from "@material-ui/core/styles/makeStyles"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles(theme => ({
  root: {
    border: `1px solid rgba(238, 238, 238, 1)`,
    padding: 0,
    textAlign: "left",

    [theme.breakpoints.down("sm")]: {
      marginLeft: -theme.spacing(3),
      borderLeft: `medium none`,
      borderRight: `medium none`,
      width: "100vw",
      padding: 0,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: -theme.spacing(2),
      borderLeft: `medium none`,
      borderRight: `medium none`,
      width: "100vw",
      padding: 0,
    },
  },
  child: {
    [theme.breakpoints.down("xs")]: {
      minWidth: "50%",
      maxWidth: "50%",
      flexGrow: 0,
      flexBasis: "50%"
    },
  },
}))

const Filters = ({ children, ...props }) => {
  const classes = useStyles()

  const childrenList = Children.toArray(children)

  return (
    <Grid container {...props} className={classes.root} alignItems="center">
      {Children.map(childrenList, (child, index) => (
        <Grid item className={classes.child} {...child.props.itemprops}>
          {child}
        </Grid>
      ))}
    </Grid>
  )
}

Filters.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
}

export default Filters

import React from "react"
import PropTypes from "prop-types"

import { makeStyles, withStyles } from "@material-ui/core/styles"

import CloseIcon from "../icons/CloseIcon"

import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"

const CloseButton = withStyles(theme => ({
  root: {
    background: "#eee",
    fontSize: 14,
    borderRadius: 0,
    textTransform: "none",
    justifyContent: "flex-start",
    lineHeight: "22px",
    padding: "5px 10px",
    transitionDuration: "0s !important",
    textAlign: 'left',

    "&:active": {},

    "&:hover": {
      background: "#eee",
      ["@media (hover: hover) and (pointer: fine)"]: {},
    },

    "&:focus": {},

    "&.selected": {
      background: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
  },
}))(Button)

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.text.secondary,
    fontSize: 16,
  },
  buttons: {
    display: "fex",
  },
}))

const FilterBy = ({ filters }) => {
  const classes = useStyles()

  if (
    filters.filter(f => f.filter.filter(option => option.selected).length > 0)
      .length > 0
  ) {
    return (
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Typography className={classes.text}>Filtrare după</Typography>
        </Grid>
        {filters.map(f =>
          f.filter
            .filter(option => option.selected)
            .map(option => (
              <Grid item key={option.uid}>
                <CloseButton
                  color="secondary"
                  onClick={() => f.onRemove(option)}
                >
                  {option.name}
                  &nbsp;
                  <CloseIcon style={{ fontSize: 15, marginLeft: 10 }} />
                </CloseButton>
              </Grid>
            ))
        )}
      </Grid>
    )
  }

  return <></>
}

FilterBy.propTypes = {
  filters: PropTypes.array.isRequired,
}

export default FilterBy

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import {
  createTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles"

import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"

import SearchIcon from "@material-ui/icons/Search"

const SearchField = withStyles(theme => ({
  root: {
    "& .MuiInputLabel-filled": {
      fontSize: 16,
      transform: "translate(12px, 10px) scale(1)",
    },
    "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
      fontSize: 10,
      transform: "translate(12px, 2px) scale(1)",
      display: "none",
    },
    "& .MuiFilledInput-root": {
      borderRadius: 0,
      background: "rgba(0, 0, 0, 0.09)",

      [theme.breakpoints.down("sm")]: {
        background: "#fff",
      },
    },
    "& .MuiFilledInput-input": {
      fontSize: 16,
      borderRadius: 0,
      height: 35,
      padding: "0 0 0 12px",
    },

    "& .MuiFilledInput-underline:after": {
      display: "none",
    },
    "& .MuiFilledInput-underline:before": {
      display: "none",
    },

    "& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)":
    {
      margin: 0,
    },
  },
}))(TextField)

const useStyles = makeStyles(theme => ({
  root: {},
}))

const TextFilter = ({ id, placeholder, onFilter }) => {
  const classes = useStyles()

  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onFilter(searchTerm)
    }, 100)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  return (
    <SearchField
      fullWidth
      type="text"
      id={id}
      label={placeholder}
      onChange={e => setSearchTerm(e.target.value)}
      name="name"
      variant="filled"
      autoComplete="off"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon
              style={{ fontSize: 20, color: "rgba(0, 0, 0, 0.54)" }}
            />
          </InputAdornment>
        ),
      }}
    />
  )
}

TextFilter.defaultProps = {
  id: 'nameFilter',
}

TextFilter.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default TextFilter

import { Card, CardActionArea, CardContent, Collapse, Grid, Typography, makeStyles, useMediaQuery, useTheme } from "@material-ui/core"
import React, { useState } from "react"
import { navigate, Link } from "gatsby"
import { localizedSlugPrefix } from "../../gatsby/nodeHelpers"
import { GatsbyImage } from "gatsby-plugin-image"

import ArrowDownIcon from "../icons/ArrowDownIcon"
import ArrowUpIcon from "../icons/ArrowUpIcon"

import CustomButton from "../buttons/CustomButton"

const useStyles = makeStyles(theme => ({
    card: {
        textAlign: "center",
        padding: 0,
    },
    cardAction: {
        display: "block",
        textAlign: "left",
    },
    cardContent: {
        minHeight: 50,
        padding: 0,
        textAlign: "left",
    },
    media: {
        height: 220,
    },
    name: {
        paddingTop: theme.spacing(2),
    },
}))


const SpecialisationGroup = ({ slug, specialisation }) => {
    const classes = useStyles()
    const [isCollapsed, setIsCollapsed] = useState(true)

    const isXsUp = useMediaQuery(theme => theme.breakpoints.up('xs'));
    const isSmUp = useMediaQuery(theme => theme.breakpoints.up('sm'));
    const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

    let cols = null
    if (isXsUp) {
        cols = 2
    }
    if (isSmUp) {
        cols = 4
    }
    if (isMdUp) {
        cols = 6
    }

    const shouldShowMoreButton = specialisation.team_members.length > cols

    return (
        <Grid item xs={12}>
            <h1>{specialisation.title.text}</h1>
            <Collapse in={!isCollapsed} collapsedSize={250 * (specialisation.number_displayed_rows ?? 1)} orientation="horizontal">
                <Grid container spacing={2}>
                    {specialisation.team_members
                        .filter(({ team_member: { document: member } }) => member)
                        .map(({ team_member: { document: member } }) =>
                            <Grid item xs={6} sm={4} md={2} key={member.uid}>
                                <Card className={classes.card} elevation={0} square>
                                    <CardActionArea
                                        className={classes.cardAction}
                                        onClick={() => {
                                            navigate(
                                                `${localizedSlugPrefix(member)}/${slug}/${member.uid
                                                }/`
                                            )
                                        }}
                                    >
                                        <GatsbyImage
                                            className={classes.media}
                                            alt={member.data.name.text}
                                            image={member.data.profile_image.gatsbyImageData}
                                        />
                                    </CardActionArea>
                                    <CardContent className={classes.cardContent}>
                                        <Link
                                            to={`${localizedSlugPrefix(member)}/${slug}/${member.uid}/`}
                                        >
                                            <Typography
                                                className="doctorName"
                                                component="h3"
                                                variant="body1"
                                            >
                                                {member.data.name.text}
                                            </Typography>
                                            <Typography
                                                component="h3"
                                                variant="body2"
                                                className="doctorSpecialisation"
                                                color="primary"
                                            >
                                                {/* member.data.specialisations.filter(({ specialisation }) => specialisation.document).length > 0 &&
                                      member.data.specialisations[0].specialisation
                              .document.data.title.text */ }
                                            </Typography>
                                        </Link>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )}
                </Grid>
            </Collapse>
            <br />
            {shouldShowMoreButton && (
                <CustomButton
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    size="small"
                    color="secondary"
                    variant="contained"
                    fullWidth
                >
                    {isCollapsed ? <>
                        <ArrowDownIcon style={{ fontSize: 14, height: 25, marginLeft: 5 }} />
                        &nbsp;&nbsp;Vezi toți medicii
                    </> :
                        <>
                            <ArrowUpIcon style={{ fontSize: 14, height: 25, marginLeft: 5 }} />
                            &nbsp;&nbsp;Vezi mai puțini medici
                        </>}
                </CustomButton>
            )}

        </Grid>
    )
}

export default SpecialisationGroup


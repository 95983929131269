import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"

import {
  withStyles,
  makeStyles,
} from "@material-ui/core/styles"

import ArrowDownIcon from "../icons/ArrowDownIcon"
import ArrowUpIcon from "../icons/ArrowUpIcon"

import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"

const Panel = withStyles(theme => ({
  root: {
    border: '1px solid rgba(238, 238, 238, 1)',
    padding: "15px 12px",
    width: 400,
    outline: 0,
    position: 'absolute',

    [theme.breakpoints.up("md")]: {
      left: "0 !important"
    },

    [theme.breakpoints.down("sm")]: {
      width: "100vw",

    },

    [theme.breakpoints.down("xs")]: {
      width: "100vw",
    },
  },
}))(Paper)

const Option = withStyles(theme => ({
  root: {
    background: "#eee",
    fontSize: 14,
    borderRadius: 0,
    textTransform: "none",
    justifyContent: "flex-start",
    padding: "5px 10px",
    transitionDuration: "0s !important",
    textAlign: 'left',

    "&:active": {},

    "&:hover": {
      background: "#eee",
      ["@media (hover: hover) and (pointer: fine)"]: {},
    },

    "&:focus": {},

    "&.selected": {
      background: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
  },
}))(Button)

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
  },
  button: {
    cursor: "pointer",
    display: "flex",
    color: "inherit",
    userSelect: "none",
    transitionDuration: "0s !important",
    boxShadow: "none",
    borderRadius: 0,
    textTransform: "none",
    fontSize: 15,
    position: "relative",
    lineHeight: 1.5,
    fontWeight: 300,
    padding: "13px 20px",
    borderRight: '1px solid transparent',
    borderLeft: '1px solid transparent',

    "&.isFirst": {
      borderLeft: 'medium none',
    },

    "&:hover": {
      background: "none",
    },
    "&:active": {},

    "&:focus": {},

    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },

    [theme.breakpoints.down("xs")]: {
      padding: 10,
    },
  },
  buttonOpen: {
    borderRight: '1px solid rgba(238, 238, 238, 1)',
    borderLeft: '1px solid rgba(238, 238, 238, 1)',

    "&.isFirst": {
      borderLeft: 'medium none',
    },

    "&::after": {
      content: '""',
      position: "absolute",
      width: "100%",
      background: "#fff",
      height: 10,
      bottom: -5,
      zIndex: 9,
      left: 0,
    },
  },

  cancelButton: {
    fontSize: 14,
    border: "1px solid #666",
    borderRadius: 0,
    textTransform: "none",
    padding: "0 5px",
    color: "#666",
    fontWeight: 300,
  },

  selectedCountWrapper: {
    height: 30,
  },

  selectedCount: {
    fontSize: 12,
  },
  selectedItems: {
    fontSize: 12,
    color: "#999",
  },

  optionsList: {
    maxHeight: "50vh",
    overflowY: "scroll",
  },
}))

const SingleSelectFilter = ({ title, options, onFilter, isFirst }) => {
  const classes = useStyles()

  const [currentOptions, setCurrentOptions] = useState(options)
  const ref = useRef()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [panelPositionX, setPanelPositionX] = useState(0)


  const onSelectOption = option => {
    const optionIdx = currentOptions.findIndex(o => o.id === option.id)

    currentOptions.filter(option => option != currentOptions[optionIdx]).map(option => option.selected = false)

    currentOptions[optionIdx].selected = !currentOptions[optionIdx].selected
    setCurrentOptions([...currentOptions])

    onFilter(currentOptions)

    setTimeout(() => setIsPopoverOpen(false), 200)
  }

  useEffect(() => {
    const dimensions = ref.current.getBoundingClientRect();

    setPanelPositionX(0 - dimensions.x)

    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isPopoverOpen && ref.current && !ref.current.contains(e.target)) {
        setIsPopoverOpen(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isPopoverOpen])




  return (
    <div className={classes.root} ref={ref} >
      <a
        role="button"
        rel="nofollow"
        className={clsx(classes.button, isPopoverOpen ? classes.buttonOpen : "", isFirst ? 'isFirst' : '')}
        onClick={() => {
          setIsPopoverOpen(!isPopoverOpen)
        }}
      >
        <span>{title}</span>
        {isPopoverOpen ? (
          <ArrowUpIcon
            style={{ fontSize: 14, height: 25, marginLeft: 5 }}
          />
        ) : (
          <ArrowDownIcon
            style={{ fontSize: 14, height: 25, marginLeft: 5 }}
          />
        )}
      </a>

      {isPopoverOpen && <Panel
        elevation={0}
        square
        tabIndex={-1}
        className={classes.popover}
        style={{ left: panelPositionX }}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid
              container
              justifyContent="center"
              alignItems="stretch"
              spacing={1}
              className={classes.optionsList}
            >
              {currentOptions.map(option => (
                <Grid item xs={12} key={option.id}>
                  <Option
                    fullWidth
                    onClick={() => onSelectOption(option)}
                    className={option.selected ? "selected" : ""}
                  >
                    {option.name}
                  </Option>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Panel>}
    </div>

  )
}

SingleSelectFilter.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onFilter: PropTypes.func.isRequired,
  isFirst: PropTypes.bool
}

export default SingleSelectFilter

import { graphql, useStaticQuery } from "gatsby"
import { uniqBy, sortBy, orderBy } from "lodash"

export default (contactLocationUids, specialisationUids) => {
  const data = useStaticQuery(graphql`
    query SimilarTeamMembersQuery {    
      specialisations: allPrismicSpecialisation {
        edges {
          node {
            uid
            data {
              position
              title {
                text
              }
              number_displayed_rows
              team_members {
                team_member {
                  document {
                    ... on PrismicTeamMember {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
      contactLocations: allPrismicContactLocation {
        edges {
          node {
            id
            uid
            lang
            data {
              position
              title {
                text
              }
              long_title {
                text
              }
              show_team_member_last
              gallery {
                image {
                  alt
                  gatsbyImageData(layout: FULL_WIDTH)
                  url
                }
              }
              description {
                html
              }
              city {
                text
              }
              address {
                text
              }
              coordinates {
                latitude
                longitude
              }
              team_members {
                team_member {
                  document {
                    ... on PrismicTeamMember {
                      id
                      uid
                      lang
                      data {
                        entitling
                        name {
                          text
                        }
                        profile_image {
                          alt
                          gatsbyImageData(layout: CONSTRAINED, width: 500)
                        }                        
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const contactLocations = data.contactLocations.edges
    .map(r => r.node)
    .sort((a, b) => a.data.position > b.data.position ? 1 : (a.data.position < b.data.position ? -1 : 0))
  const specialisations = data.specialisations.edges
    .map(r => r.node)
    .sort((a, b) => a.data.position > b.data.position ? 1 : (a.data.position < b.data.position ? -1 : 0))

  const contactLocationsToTeamMembersMap = []

  contactLocations.map(cl => {
    cl.data.team_members
      .filter(tm => tm.team_member.document)
      .map((tm, key) => {
        tm.team_member.document.position = key
        if (!contactLocationsToTeamMembersMap[cl.uid]) {
          contactLocationsToTeamMembersMap[cl.uid] = []
        }

        const tmSpecialisations = getTeamMemberSpecialisations(specialisations, tm.team_member.document.id)

        tmSpecialisations
          .forEach((specialisation) => {
            if (!contactLocationsToTeamMembersMap[cl.uid][specialisation.uid]) {
              contactLocationsToTeamMembersMap[cl.uid][specialisation.uid] = []
            }
            contactLocationsToTeamMembersMap[cl.uid][specialisation.uid].push({
              ...tm.team_member.document,
              specialisations: tmSpecialisations,
              showLast: !!cl.data.show_team_member_last
            })
          })
      })
  })

  let similar = []

  if (!contactLocationUids) {
    contactLocationUids = contactLocations.map(cl => cl.uid)
  }

  if (!specialisationUids) {
    specialisationUids = specialisations.map(s => s.uid)
  }

  contactLocationUids.forEach(contactLocationUid => {
    specialisationUids.forEach(specialisationUid => {
      if (contactLocationsToTeamMembersMap[contactLocationUid][specialisationUid]) {
        similar = [...similar, ...contactLocationsToTeamMembersMap[contactLocationUid][specialisationUid]]
      }
    })
  })

  const sorted = uniqBy(orderBy(sortBy(similar, ['position']), 'showLast', 'asc'), i => i.uid)

  return sorted
}

const getTeamMemberSpecialisations = (specialisations, teamMemberId) => {
  return specialisations.filter(s => s.data.team_members.some(tm => tm.team_member?.document?.id === teamMemberId))
}
